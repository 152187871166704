import { Image, ItemClip, ItemReference, Point, Position, Shape, Subpanel, TextArea } from "@mcp-artwork/cimdoc-types-v2";
import { OrderableItemInfo, PreviewType } from "../../models/Layout";
import {
  BoundingBox,
  boundingBoxFromEllipseItem,
  boundingBoxFromLineItem,
  boundingBoxFromPath,
  boundingBoxFromRectangleItem,
  computeBoundsFromPosition,
} from "../../utils/boundingBox";
import { MeasurementDataResponse, getMeasurementData } from "../../layout/measurements/measurementData";
import { Point as PointD } from "../../utils/math/geometry";
import { FallBackItemOptions } from "../Item";
import { getPreviewMetadataFromFallback } from "./getFallbackItem";
import { buildTransform } from "../../layout/helpers/Transform";
import { curveLegacyToCurve } from "../../utils/curveLegacy";
import { parsePathData } from "../../utils/parsePathData";
import { calculateViewboxTransform } from "../../layout/shapes/Curve";
import { Matrix } from "../../utils/math/matrix";

export type GetItemForPanelReturn = {
  originalPosition?: Point;
  originalClipping?: ItemClip;
  translation?: PointD;
  rotation?: number | string;
  transform: Matrix;
  measurementData?: MeasurementDataResponse;
  itemToAdd: TextAreaReturn | ItemReferenceReturn | ImageReturn | SubpanelReturn | ShapeReturn;
};

type TextAreaReturn = {
  textAreas: [TextArea];
};

type ItemReferenceReturn = {
  itemReferences: [ItemReference];
};

type ImageReturn = {
  images: [Image];
};

type SubpanelReturn = {
  subpanels: [Subpanel];
};

type ShapeReturn = {
  shapes: [Shape];
};

// Set position of items to 0,0 and rotationAngle to 0 for sending to the server for a preview
export async function getItemForPanel({
  itemInfo,
  previewType,
  fallbackOptions,
}: {
  itemInfo: OrderableItemInfo;
  previewType: PreviewType;
  fallbackOptions: FallBackItemOptions;
}): Promise<GetItemForPanelReturn> {
  if (itemInfo.itemType === "textArea") {
    const textArea = itemInfo.item;

    const boundingBox = computeBoundsFromPosition({ position: textArea.position });
    const originalScaleTransform = textArea.scale;

    const transform = buildTransform({
      bounds: boundingBox,
      skew: textArea.skew,
      scale: textArea.scale,
      rotationAngle: textArea.rotationAngle,
      matrixTransform: textArea.transform,
      itemTransforms: textArea.transforms,
    });

    const measurementData = getMeasurementData({
      boundingBox: boundingBox,
      // For text, we don't know the tight bounds. This is a placeholder value so that the
      // function would work, but we wouldn't be actually using the resulting previewBox.
      tightBounds: boundingBox,
      transform: transform,
      scaleTransform: originalScaleTransform,
      itemType: itemInfo.itemType,
    });

    return {
      originalPosition: textArea.position,
      originalClipping: textArea.clipping,
      measurementData: measurementData,
      transform: transform,
      itemToAdd: {
        textAreas: [
          {
            ...textArea,
            position: {
              ...textArea.position,
              x: "0mm",
              y: "0mm",
            },
            scale: undefined,
            skew: undefined,
            rotationAngle: undefined,
            clipping: undefined,
            transforms: undefined,
            transform: {
              a: measurementData.fallbackOptimizedItemPreviewTransform.transform.a,
              b: measurementData.fallbackOptimizedItemPreviewTransform.transform.b,
              c: measurementData.fallbackOptimizedItemPreviewTransform.transform.c,
              d: measurementData.fallbackOptimizedItemPreviewTransform.transform.d,
              x: `${measurementData.fallbackOptimizedItemPreviewTransform.transform.x}mm`,
              y: `${measurementData.fallbackOptimizedItemPreviewTransform.transform.y}mm`,
            },
          },
        ],
      },
    };
  }
  if (itemInfo.itemType === "itemReference") {
    const itemRef = itemInfo.item;
    const boundingBox = computeBoundsFromPosition({ position: itemRef.position });
    const previewBox = boundingBox;
    const originalScaleTransform = itemRef.scale;

    const transform = buildTransform({
      bounds: boundingBox,
      rotationAngle: itemRef.rotationAngle,
      scale: originalScaleTransform,
    });

    const measurementData = getMeasurementData({
      boundingBox: boundingBox,
      tightBounds: previewBox,
      transform: transform,
      itemType: itemInfo.itemType,
      scaleTransform: originalScaleTransform,
    });

    return {
      originalPosition: itemRef.position,
      measurementData: measurementData,
      transform: Matrix.identity(),
      itemToAdd: {
        itemReferences: [
          {
            ...itemRef,
            position: {
              ...itemRef.position,
              x: "0mm",
              y: "0mm",
            },
            scale: undefined,
            rotationAngle: undefined,
            transforms: undefined,
            transform: {
              a: measurementData.fallbackOptimizedItemPreviewTransform.transform.a,
              b: measurementData.fallbackOptimizedItemPreviewTransform.transform.b,
              c: measurementData.fallbackOptimizedItemPreviewTransform.transform.c,
              d: measurementData.fallbackOptimizedItemPreviewTransform.transform.d,
              x: `${measurementData.fallbackOptimizedItemPreviewTransform.transform.x}mm`,
              y: `${measurementData.fallbackOptimizedItemPreviewTransform.transform.y}mm`,
            },
          },
        ],
      },
    };
  }
  if (itemInfo.itemType === "subpanel") {
    const subpanel = itemInfo.item;

    const originalScaleTransform = subpanel.scale;
    const previewMetadata = await getPreviewMetadataFromFallback(subpanel, fallbackOptions);
    const previewBox = previewMetadata.previewBox;
    const boundingBox = { ...previewBox };

    const transform = buildTransform({
      bounds: boundingBox,
      skew: subpanel.skew,
      scale: subpanel.scale,
      rotationAngle: subpanel.rotationAngle,
      matrixTransform: subpanel.transform,
      itemTransforms: subpanel.transforms,
    });

    const measurementData = getMeasurementData({
      boundingBox: boundingBox,
      tightBounds: previewBox,
      transform: transform,
      scaleTransform: originalScaleTransform,
      itemType: itemInfo.itemType,
    });

    const itemPreviewTransform = measurementData.itemPreviewTransform;

    return {
      originalPosition: subpanel.position,
      measurementData: measurementData,
      rotation: "0",
      transform: Matrix.identity(),
      itemToAdd: {
        subpanels: [
          {
            ...subpanel,
            position: {
              x: "0mm",
              y: "0mm",
            },
            scale: undefined,
            skew: undefined,
            rotationAngle: undefined,
            transforms: undefined,
            transform: {
              a: itemPreviewTransform.a,
              b: itemPreviewTransform.b,
              c: itemPreviewTransform.c,
              d: itemPreviewTransform.d,
              x: `${itemPreviewTransform.x}mm`,
              y: `${itemPreviewTransform.y}mm`,
            },
          },
        ],
      },
    };
  }
  if (itemInfo.itemType === "image") {
    const image: Image = itemInfo.item;

    const boundingBox = computeBoundsFromPosition({ position: image.position });
    const originalScaleTransform = image.scale;

    const transform = buildTransform({
      bounds: boundingBox,
      skew: image.skew,
      scale: image.scale,
      imageAlignment: {
        horizontalAlignment: image.horizontalAlignment,
        verticalAlignment: image.verticalAlignment,
      },
      rotationAngle: image.rotationAngle,
      mirrorDirection: image.mirrorDirection,
      matrixTransform: image.transform,
      itemTransforms: image.transforms,
    });

    const measurementData = getMeasurementData({
      boundingBox: boundingBox,
      tightBounds: boundingBox,
      transform: transform,
      scaleTransform: originalScaleTransform,
      itemType: itemInfo.itemType,
    });

    return {
      originalPosition: image.position,
      originalClipping: image.clipping,
      measurementData: measurementData,
      transform: transform,
      itemToAdd: {
        images: [
          {
            ...image,
            position: {
              ...image.position,
              x: "0mm",
              y: "0mm",
            },
            scale: undefined,
            skew: undefined,
            rotationAngle: undefined,
            clipping: undefined,
            transforms: undefined,
            transform: {
              a: measurementData.fallbackOptimizedItemPreviewTransform.transform.a,
              b: measurementData.fallbackOptimizedItemPreviewTransform.transform.b,
              c: measurementData.fallbackOptimizedItemPreviewTransform.transform.c,
              d: measurementData.fallbackOptimizedItemPreviewTransform.transform.d,
              x: `${measurementData.fallbackOptimizedItemPreviewTransform.transform.x}mm`,
              y: `${measurementData.fallbackOptimizedItemPreviewTransform.transform.y}mm`,
            },
          },
        ],
      },
    };
  }
  if (itemInfo.itemType === "shape") {
    const shape = itemInfo.item;
    const originalScaleTransform = shape.scale;
    let boundingBox: BoundingBox;

    if (shape.type === "rectangle") {
      boundingBox = boundingBoxFromRectangleItem({ item: shape });
    } else if (shape.type === "curve") {
      const curve = curveLegacyToCurve({ curve: shape });

      const { scaleX, scaleY, translateX, translateY } = calculateViewboxTransform(curve);

      // Use scale and translate to adjust position using the viewbox
      const [svgPath] = parsePathData({
        pathData: curve.svgPathData ?? "",
        pixelSize: 1,
        svgPathDataUnit: curve.svgPathDataUnit ?? "pt",
        closeBehavior: curve.closeBehavior,
        scaleX,
        scaleY,
        translateX,
        translateY,
      });

      boundingBox = boundingBoxFromPath({ path: svgPath, stroke: shape.stroke });
    } else if (shape.type === "ellipse") {
      boundingBox = boundingBoxFromEllipseItem({ item: shape });
    } else {
      // Assume line
      boundingBox = boundingBoxFromLineItem({ line: shape });
    }
    const transform = buildTransform({
      bounds: boundingBox,
      skew: shape.skew,
      scale: shape.scale,
      rotationAngle: shape.rotationAngle,
      matrixTransform: shape.transform,
      itemTransforms: shape.transforms,
    });

    const measurementData = getMeasurementData({
      boundingBox: boundingBox,
      tightBounds: boundingBox,
      transform: transform,
      scaleTransform: originalScaleTransform,
      itemType: itemInfo.itemType,
    });

    if (shape.type !== "line") {
      return {
        originalPosition: shape.position,
        measurementData: measurementData,
        itemToAdd: {
          shapes: [
            {
              ...shape,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              position: { ...shape.position, x: "0mm", y: "0mm" },
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              rotationAngle: undefined,
              scale: undefined,
              skew: undefined,
              transforms: undefined,
              transform: {
                a: measurementData.fallbackOptimizedItemPreviewTransform.transform.a,
                b: measurementData.fallbackOptimizedItemPreviewTransform.transform.b,
                c: measurementData.fallbackOptimizedItemPreviewTransform.transform.c,
                d: measurementData.fallbackOptimizedItemPreviewTransform.transform.d,
                x: `${measurementData.fallbackOptimizedItemPreviewTransform.transform.x}mm`,
                y: `${measurementData.fallbackOptimizedItemPreviewTransform.transform.y}mm`,
              },
            },
          ],
        },
      };
    }

    // line item
    return {
      //
      originalPosition: { x: `0mm`, y: `0mm` },
      measurementData: measurementData,
      transform: Matrix.identity(),
      itemToAdd: {
        shapes: [
          {
            ...shape,
            scale: undefined,
            rotationAngle: undefined,
            skew: undefined,
            transforms: undefined,
            transform: {
              a: measurementData.fallbackOptimizedItemPreviewTransform.transform.a,
              b: measurementData.fallbackOptimizedItemPreviewTransform.transform.b,
              c: measurementData.fallbackOptimizedItemPreviewTransform.transform.c,
              d: measurementData.fallbackOptimizedItemPreviewTransform.transform.d,
              x: `${measurementData.fallbackOptimizedItemPreviewTransform.transform.x}mm`,
              y: `${measurementData.fallbackOptimizedItemPreviewTransform.transform.y}mm`,
            },
          },
        ],
      },
    };
  }
  throw Error("Attempted to fallback on a subpanel");
}
